import React from 'react';
import './App.css';
import MainPage from './pages/main/main'
import TopNavbar from './components/navbar/topnavbar';
import FIBrand from './components/fi_brand/fi_brand';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import AllerLight from './resources/fonts/Aller_Lt.ttf';
import Footer from './components/footer/footer';

const raleway = {
  fontFamily: 'Aller',
  fontStyle: 'l',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    local('Aller'),
    local('Aller-Regular'),
    url(${AllerLight})
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Aller',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
});


function App() {
 

  return (
    <MuiThemeProvider theme={theme}>
    <CssBaseline />
      <Router>
      <div>
      
        <TopNavbar></TopNavbar>
       
        <MainPage/>
          
        <Footer></Footer>
      </div>
     
    </Router>
    </MuiThemeProvider>
    
  );
}

export default App;