import React from 'react';
import './splash.css'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LogoRed from '../../resources/images/logo512.png'
import { Typography,Box } from '@material-ui/core';
function Splash() {


    return(
        <Grid container spacing={2} direction="column"  alignItems="center"  justify="center"  className = "splashWrapper">
      
            <Grid item>
                <img className="splash_logo" src={LogoRed}></img>
            </Grid>
            <Grid item>
                <Typography variant="h4" component="h4" className='banner'>
                    <Box textAlign="center">
                    Engenharia Eletrotécnica |   Projeto Elétrico  |  Projeto Automação e Controlo 
                    </Box>
                </Typography>
            </Grid>   
      
        </Grid> 
    );
}

export default Splash;