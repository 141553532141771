import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Grid from '@material-ui/core/Grid';
import LogoRed from '../../resources/images/logo512.png'
import Logo from '../../resources/images/logo_hor_white.png'

import { HashLink as RouterLink } from 'react-router-hash-link';
import Link from '@material-ui/core/Link';
import { MemoryRouter as Router } from 'react-router';

import './topnavbar.css';
import { Container } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      position: 'fixed'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

  function HandleMenuClick()
  {
    alert("me");
  }

function TopNavbar(props) {
 
    const classes = useStyles();
    
    const trigger = useScrollTrigger({threshold:100,disableHysteresis:true});
    
    let className = 'transp_bg';

    if(trigger)
    {
      className = 'opaque_bg'
    }

    return(
     
        <AppBar fixed="true"  className={className}>
            <Toolbar>
            
              <Grid container spacing={3}  direction="row"  justify="center" alignItems="center">
                <Grid item xs={3}>
                  <Button component={RouterLink} to="/" color="inherit">
                    <img className="logo" src={Logo}></img>
                  </Button>
                </Grid>
                  <Grid item xs={6} align="center">
                    <Box clone display={{ xs:"none",md: "inline-flex" }}>
                      <Button component={RouterLink} to="/#services" color="inherit">
                        Serviços
                      </Button>
                    </Box>
                    <Box clone display={{ xs:"none",md: "inline-flex" }}>
                      <Button component={RouterLink} to="/#portfolio" color="inherit">
                        Portefólio
                      </Button>
                    </Box>
                    <Box clone display={{ xs:"none",md: "inline-flex" }}>
                      <Button component={RouterLink} to="/#contacts" color="inherit">
                        Contacte-nos
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={3} align="right">
                    <Box clone display={{ xs:"inline-flex",md: "none" }}>
                    <IconButton edge="start" onClick={HandleMenuClick} className={classes.menuButton} color="inherit" aria-label="menu">
                      <MenuIcon />
                    </IconButton>
                    </Box>
                  </Grid>
              </Grid>
            </Toolbar>
        </AppBar>
        
        
    );
}

export default TopNavbar;