import React from 'react';
import './services.css'
import {Container, Grid } from '@material-ui/core';
import ServRec from '../../resources/images/serv_rec.jpg';
import PrestServ from '../../resources/images/pres_serv.jpg';
import AssTec from '../../resources/images/serv_ass.jpg';
import Proj from '../../resources/images/proj.jpg';
import InstEle from '../../resources/images/inst_ele.jpg';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    height:'100%',
    textAlign:'justify'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  header:{
    minHeight:'96px',
    textAlign:'center'
  },
 
    cardcontent: {
        marginBottom:0,
    },  
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function Services() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return(
        
            <div id='services'>
            <Container style = {{paddingTop:'50px'}}>
                <Typography variant="h3" component="h2" align="left" className="title">A nossa missão</Typography>
                <br></br>
                <Typography variant="body1" className="textP"> O objetivo da JTFaustino Engenharia Eletrotécnica é fornecer aos seus clientes, em todos os ramos da indústria, assistência e manutenção de equipamentos e máquinas, a um custo ideal.
                As áreas de negócio da cerâmica, transformação da pedra, transformação da madeira, energias renováveis e eficiência energética são setores de atividade específicos que estão sempre a enfrentar novos desafios. Isso permite que cada unidade de negócio se especialize e, consequentemente, necessite das melhores soluções.
                Os objetivos do nosso projeto empresarial passam por atender às mais altas expectativas dos nossos clientes, fornecedores e outras partes interessadas no nosso trabalho, fornecendo confiabilidade e serviços de engenharia especializados.
                Deste modo, a missão da JTFaustino Engenharia Eletrotécnica é suportada pelos valores da especialização, flexibilidade, orientação ao cliente, inovação, entusiasmo e dinamismo, que estarão sempre presentes no ímpeto da nossa empresa.</Typography>
            </Container>
            <Container style = {{marginTop:'50px'}}>
               
            <Typography variant="h3" component="h2" align="left" className="title">Serviços</Typography>

                <Grid container  spacing={5} justify="center" className={classes.cardcontent}>
                    <Grid item >
                    <Fade bottom>
                    <div style={{ height: '100%' }}>
                        <Card className={classes.root} >
                        <CardHeader
                            title="Recondicionamento de máquinas" className={classes.header}
                        />
                        <CardMedia
                            className={classes.media}
                            image={ServRec}
                            title="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Recondicionamento de máquinas dos setores industriais da cerâmica e do plástico nomeadamente fornos intermitentes e contínuos, máquinas de injeção e extrusão Etar’s.
                            </Typography>
                        </CardContent>
                    </Card>
                    </div>
                    </Fade>
                    </Grid>
                    <Grid item>
                        <Fade bottom>
                        <div style={{ height: '100%' }}>
                            <Card className={classes.root} style={{ height: '100%' }} >
                            <CardHeader title="Prestação de serviços" className={classes.header}/>
                            <CardMedia
                                className={classes.media}
                                image={PrestServ}
                                title="Paella dish"
                            />
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Prestação de serviços de instrumentação e automação industrial com destaque para o controlo de temperatura, programação de PLC’s e robótica.
                                </Typography>
                            </CardContent>
                            </Card>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item>
                    <Fade bottom>
                    <div style={{ height: '100%' }}>
                        <Card className={classes.root} >
                        <CardHeader
                            title="Assistência técnica" className={classes.header}
                        />
                        <CardMedia
                            className={classes.media}
                            image={AssTec}
                            title="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                            Assistência técnica das industrias de transformação de pedra e madeira.
                            </Typography>
                        </CardContent>
                    </Card>
                    </div>
                    </Fade>
                    </Grid>
                    <Grid item>
                    <Fade bottom >
                    <div style={{ height: '100%' }}>
                      <Card className={classes.root}>
                        <CardHeader
                            title="Estudos e projetos na área das energias" className={classes.header}
                        />
                        <CardMedia
                            className={classes.media}
                            image={Proj}
                            title="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                            Estudos e projetos na área das energias renováveis e eficiência energética no âmbito da energia eólica, fotovoltaica e solar térmica (painéis de aquecimento solar).
                            </Typography>
                        </CardContent>
                        </Card>
                        </div>
                    </Fade>
                    </Grid>
                    <Grid item>
                        <Fade bottom >
                            <div style={{ height: '100%' }}>
                                <Card className={classes.root} >
                                    <CardHeader
                                        title="Instalações de energia elétrica do setor terciário"  className={classes.header}
                                    />
                                    <CardMedia
                                        className={classes.media}
                                        image={InstEle}
                                        title="Paella dish"
                                    />
                                    <CardContent>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Instalações de energia elétrica do setor terciário – responsabilidade pela execução com inscrição na ordem dos engenheiros técnicos com o Nº 23512.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Fade>
                    </Grid>
                </Grid>
            
            </Container>
            </div>
    );
}

export default Services;