import React from 'react';
import './portfolio.css'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import { Paper, Button } from '@material-ui/core';
import {Container, Grid,Box } from '@material-ui/core';
import Rec1 from '../../resources/images/rec1.jpg';
import Rec2 from '../../resources/images/rec2.jpg';
import Rec3 from '../../resources/images/rec3.jpg';
import Rec4 from '../../resources/images/rec4.jpg';
import PrestServ from '../../resources/images/pres_serv.jpg';
import AssTec from '../../resources/images/serv_ass.jpg';
import Proj from '../../resources/images/proj.jpg';
import InstEle from '../../resources/images/inst_ele.jpg';
import Typography from '@material-ui/core/Typography';


function Portfolio() {

    const AutoplaySlider = withAutoplay(AwesomeSlider);
   
    const slider = (
        <AutoplaySlider  play={true} buttons={false} bullets={false}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={3500}>
            <med data-src={Rec1} />
            <div data-src={Rec2} />  
            <div data-src={Rec3} />  
            <div data-src={Rec4} />  
        </AutoplaySlider>
      );

    return (
        <div>
        <Container style = {{paddingTop:'50px'}}>
            
                <Typography variant="h3" component="h2" align="left" className="title">Portefólio</Typography>
                </Container>
                <div id="portfolio" style={{maxHeight:'85vh',backgroundColor:'#e30613'}}>{slider}</div>
                </div>
    )
    
}

export default Portfolio;
