import React from 'react';
import Container from '@material-ui/core/Container';
import Splash from '../../areas/splash/splash';
import Services from '../../areas/services/services';
import Portfolio from '../../areas/portfolio/portfolio';
import About from '../../areas/about/about';
import './main.css';
import { Box,Paper } from '@material-ui/core';


function Main() {
    return(
       
            <Container maxWidth='xl' className='mainContainer'>
                <Splash></Splash>
                <Paper elevation={3}>
                    <Services></Services>
                    <Portfolio></Portfolio>
                </Paper>
            </Container>
    );
}

export default Main;