import React from 'react';
import './fi_brand.css'
import { Container, Box, Typography } from '@material-ui/core';

function Fi_Brand() {


    return(
        
        <Box >
            <Typography variant="h7" align='center'>Powered by:<a href="www.frontinfluence.com">Front Influence</a></Typography>
        </Box>
        
    );
}

export default Fi_Brand;