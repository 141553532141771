import React from 'react';
import './about.css'
import { Paper, Container, Typography } from '@material-ui/core';

function Splash() {


    return(
        
            <Container>
                <Typography variant="h3" component="h2" align="left" className="title">Nosso objetivo</Typography>
                <br></br>
                <Typography variant="body1" className="textP"> O objetivo da JTFaustino Engenharia Eletrotécnica é fornecer aos seus clientes, em todos os ramos da indústria, assistência e manutenção de equipamentos e máquinas, a um custo ideal.
    As áreas de negócio da cerâmica, transformação da pedra, transformação da madeira, energias renováveis e eficiência energética são setores de atividade específicos que estão sempre a enfrentar novos desafios. Isso permite que cada unidade de negócio se especialize e, consequentemente, necessite das melhores soluções.
    Os objetivos do nosso projeto empresarial passam por atender às mais altas expectativas dos nossos clientes, fornecedores e outras partes interessadas no nosso trabalho, fornecendo confiabilidade e serviços de engenharia especializados.
    Deste modo, a missão da JTFaustino Engenharia Eletrotécnica é suportada pelos valores da especialização, flexibilidade, orientação ao cliente, inovação, entusiasmo e dinamismo, que estarão sempre presentes no ímpeto da nossa empresa.</Typography>
            </Container>
        
    );
}

export default Splash;