import React from 'react';
import './footer.css'
import Grid from '@material-ui/core/Grid';
import LogoRed from '../../resources/images/logo512.png'
import { Typography,Box } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
function Footer() {
    return(
        
        <Grid container direction="row"  alignItems="center"  justify="center" style={{backgroundColor:"#e30613",paddingTop:"2rem",marginTop:"-1rem",color:"white",paddingBottom:"10vh"}}>
            <Grid item md={4} align="center">
                
            </Grid>
            <Grid item md={4} align="center">
                <Link style={{fontColor:'white'}} href="https://www.facebook.com/jtfaustino.engenharia" target="_blank"><FacebookIcon></FacebookIcon></Link>
            </Grid>   
            <Grid item md={4} align="center">
            
            </Grid>
        </Grid>
        
    );
}

export default Footer;